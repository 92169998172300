import React from 'react';

interface IProps {
  colorTexto?: string;
}

const ButtonIcon = (props: IProps) => (
  <svg
    role="img"
    version="1.1"
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 69 77"
    className="wompi-wbutton-icon"
  >
    <path
      fill={props.colorTexto || '#FFFFFF'}
      d="M67.1,11.5c-0.1-1-0.8-1.8-1.8-2L35.2,1.1c-0.4-0.1-0.9-0.1-1.3,0L3.7,9.5c-0.9,0.3-1.6,1.1-1.8,2	c-0.2,1.3-4.2,31,6.1,45.7C18.2,72,33.3,75.8,33.9,75.9c0.4,0.1,0.8,0.1,1.2,0C35.7,75.8,50.8,72,61,57.2	C71.3,42.5,67.3,12.8,67.1,11.5z M53.9,28.8L33.4,49.4c-0.5,0.5-1.1,0.7-1.7,0.7s-1.3-0.2-1.7-0.7L17.2,36.7c-1-1-1-2.5,0-3.5	c0,0,0,0,0,0l2.5-2.5c1-1,2.5-1,3.5,0l8.5,8.5L48,22.8c1-1,2.5-1,3.5,0c0,0,0,0,0,0l2.5,2.5C54.9,26.3,54.9,27.9,53.9,28.8	C53.9,28.8,53.9,28.8,53.9,28.8z"
    />
  </svg>
);

export default ButtonIcon;
