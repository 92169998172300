import React from 'react';

interface IProps {
  src: string;
  onCloseButtonPress(e: any): void;
}

const ModalContent = (props: IProps) => {
  const [iframeHeight, setIframeHeight] = React.useState(220);

  // Event Handlers
  const onFrameLoaded = (e: any) => {};
  const onWindowPostMessage = React.useCallback(
    (e: any) => {
      try {
        const data = JSON.parse(e.data);

        if (data) {
          if (data.message && data.message === 'sizeUpdate') {
            setIframeHeight(data.height + 35);
          } else if (data.message && data.message === 'close') {
            props.onCloseButtonPress(null);
          }
        }
      } catch {
        // TODO: Handle exception if neccesary
      }
    },
    [props]
  );

  // Effects
  React.useEffect(() => {
    try {
      window.removeEventListener('message', onWindowPostMessage);
    } catch {}

    window.addEventListener('message', onWindowPostMessage);

    return () => {
      window.removeEventListener('message', onWindowPostMessage);
    };
  }, [onWindowPostMessage]);

  return (
    <div className="wompi-wbox-modal-content">
      <iframe
        src={props.src}
        className="wompi-wbox-modal-content-iframe"
        scrolling="no"
        style={{height: iframeHeight}}
        frameBorder={0}
        title=" "
        onLoad={onFrameLoaded}
      ></iframe>
    </div>
  );
};

export default ModalContent;
