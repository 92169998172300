import React from 'react';
import './ButtonWidget.scss';
import ModalContent from './components/ModalContent';
// import ModalFooter from './components/ModalFooter';
import ModalHeader from './components/ModalHeader';
import ButtonIcon from '../Shared/components/IconButton';

interface IProps {
  // pagoId: string;
  // sinIcono?: boolean;
  // display?: string;

  urlPago: string;
  colorTexto?: string;
  colorFondo?: string;
  mostrarIcono?: boolean;
  cubrirAncho?: boolean;
}

const ButtonWidget = (props: IProps) => {
  const [visible, setVisible] = React.useState(false);

  // Event handlers
  const onButtonPress = React.useCallback((e) => {
    e.preventDefault();
    setVisible(true);
  }, []);

  const onCloseButtonPress = React.useCallback((e: any) => {
    setVisible(false);
  }, []);

  return (
    <>
      <button
        onClick={onButtonPress}
        style={{
          color: props.colorTexto || '',
          backgroundColor: props.colorFondo || '',
          display: props.cubrirAncho === true ? 'block' : 'inline-block',
          width: props.cubrirAncho === true ? '100%' : 'auto',
        }}
      >
        {!props.mostrarIcono === true && (
          <ButtonIcon colorTexto={props.colorTexto} />
        )}
        Pagar con <strong>Wompi</strong>
      </button>
      {visible && (
        <>
          <div className="wompi-wbox-backdrop" />
          <div className="wompi-wbox-modal">
            <ModalHeader onCloseButtonPress={onCloseButtonPress} />
            <ModalContent
              src={props.urlPago}
              onCloseButtonPress={onCloseButtonPress}
            />
            {/* <ModalFooter /> */}
          </div>
        </>
      )}
    </>
  );
};

export default ButtonWidget;
