import React from 'react';
import './Button.scss';
import ButtonIcon from '../Shared/components/IconButton';

interface IProps {
  urlPago: string;
  colorTexto?: string;
  colorFondo?: string;
  mostrarIcono?: boolean;
  cubrirAncho?: boolean;
}

const ButtonWidget = (props: IProps) => {
  // Event handlers
  const onButtonPress = React.useCallback(() => {
    window.open(props.urlPago, '_blank');
  }, [props.urlPago]);

  return (
    <>
      <button
        onClick={onButtonPress}
        style={{
          color: props.colorTexto || '',
          backgroundColor: props.colorFondo || '',
          display: props.cubrirAncho === true ? 'block' : 'inline-block',
          width: props.cubrirAncho === true ? '100%' : 'auto',
        }}
      >
        {!props.mostrarIcono === true && (
          <ButtonIcon colorTexto={props.colorTexto} />
        )}
        Pagar con <strong>Wompi</strong>
      </button>
    </>
  );
};

export default ButtonWidget;
