import React from 'react';
import ReactDOM from 'react-dom';

import ButtonWidget from './components/ButtonWidget/ButtonWidget';
import Button from './components/Button/Button';

const e = React.createElement;

const widgetTagList = document.querySelectorAll<HTMLElement>(
  '.wompi_button_widget'
);

widgetTagList.forEach((domContainer) => {
  try {
    // Extraemos las data properties
    const urlPago = domContainer.dataset.urlPago;
    const colorTexto = domContainer.dataset.colorTexto;
    const colorFondo = domContainer.dataset.colorFondo;
    const mostrarIcono = domContainer.dataset.mostrarIcono === 'true';
    const cubrirAncho = domContainer.dataset.cubrirAncho === 'true';
    const render = domContainer.dataset.render?.toLocaleLowerCase();

    // Si las data properties están definidas renderizamos
    if (urlPago && urlPago !== '') {
      ReactDOM.render(
        e(render === 'widget' ? ButtonWidget : Button, {
          urlPago,
          colorTexto,
          colorFondo,
          mostrarIcono,
          cubrirAncho,
        }),
        domContainer
      );
    } else {
      throw new Error();
    }
  } catch {
    console.error(
      'Uno o más parámetros no están definidos. Para más información visite https://wompi.sv/'
    );
  }
});
